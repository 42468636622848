import React, { useState } from 'react';
import './ilanlar.css';
import categories from './Data/categories.json';
import AddressFilter from './AddressFilter';
import CategoryTree from './CategoryTree';

// React Icons import
import * as FaIcons from 'react-icons/fa'; // FontAwesome kullanıyorsak

const Ilanlar = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);

  const getIconComponent = (iconName) => {
    const Icon = FaIcons[iconName];
    return Icon ? <Icon className="tab-icon" /> : null;
  };

  return (
    <div className="ilanlar-container">
      {/* Üst Sekmeler */}
      <div className='tabsconteyn'>
      <div className="tabs">
        {categories.map((category) => (
          <div
            key={category.id}
            className={`tab ${selectedCategory === category.id ? 'active' : ''}`}
            onClick={() => setSelectedCategory(category.id)}
          >
            {getIconComponent(category.icon)}
            <span>{category.name}</span>
          </div>
        ))}
      </div>
      </div>
      {/* İçerik Alanı */}
      <div className="content-area">
        <div className="left-menu">
            <AddressFilter/>
            <CategoryTree />
          {selectedCategory ? (
            <p>Alt menüler burada görünecek (Kategori ID: {selectedCategory})</p>
          ) : (
            <p></p>
          )}
        </div>
        <div className="main-content">
          {selectedCategory ? (
            <p>İlanlar buraya gelecek...</p>
          ) : (
            <p>Henüz bir kategori seçilmedi.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Ilanlar;
