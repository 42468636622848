import './App.css';
import NavbarWithSearch from './NavbarWithSearch';
import Ilanlar from './ilanlar';
import './reset.css'
import React from 'react';
import { BrowserRouter as Router, Routes, Route ,Link} from 'react-router-dom';
import Settings from './Settings';
import { useLocation } from 'react-router-dom';
import KurumsalaGecisFormu from './KurumsalaGecisFormu'

function App() {
  const location = useLocation();
  const showSearch = location.pathname !== "/settings"; // sadece settings'te gizle

  return (
    <div className="App">
      <div className="navbar">
        <NavbarWithSearch showSearch={showSearch} />
      </div>
      <div className="ilanlar" style={{ display: showSearch ? 'block' : 'none' }}>
        <Ilanlar />
      </div>

      <Routes>
        <Route path="/settings" element={<Settings />} />
        <Route path="/kurumsal-gecis" element={<KurumsalaGecisFormu />} />
       
      </Routes>
    </div>
  );
}


export default App;
