import React, { useState, useEffect } from 'react';
import './Auth.css';
import RegisterModal from './RegisterModal';
import LoginModal from './LoginModal';
import { FiSettings, FiHeart, FiLogOut } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';


const Auth = () => {
  
  const menuRef = useRef();
  const navigate = useNavigate();

  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [user, setUser] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    try {
      const storedUser = localStorage.getItem("user");
      if (storedUser) {
        setUser(JSON.parse(storedUser));
      }
    } catch {
      setUser(null);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("user");
    setUser(null);
    setMenuOpen(true);
    navigate("/"); // anasayfaya yönlendir
    window.location.reload();
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };
  
    if (menuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuOpen]);
  
  return (
    <div>
      {!user ? (
        <div className="auth-buttons">
          <button
            className="auth-button bir"
            onClick={() => setShowRegisterModal(true)}
          >
            Hesap Aç
          </button>
          <button
            className="auth-button iki"
            onClick={() => setShowLoginModal(true)}
          >
            Giriş Yap
          </button>
        </div>
      ) : (
        <div className="user-info-wrapper">
          <div className="user-info">
            <span className='usericon'>👤 {user.name}</span>
            <div className="hamburger-menu" onClick={() => setMenuOpen(!menuOpen)}>☰</div>
          </div>
          {menuOpen && (
          

            <div className="dropdown-menu" ref={menuRef}>
            
              <div className="menu-item" onClick={() => {
  navigate('/');
  setMenuOpen(false);
}}>
  🏠 Anasayfa
</div>
              <div className="menu-item" onClick={() => {  navigate('/settings');
  setMenuOpen(false);
}}>
  <FiSettings /> Ayarlarım
</div>
              <div className="menu-item"><FiHeart /> Favori İlanlarım</div>
              <div className="menu-item" onClick={handleLogout}><FiLogOut /> Çıkış Yap</div>
            </div>
          )}
        </div>
      )}

      {showRegisterModal &&
        <div className="modal-overlay" onClick={() => setShowRegisterModal(false)}>
          <RegisterModal closeModal={() => setShowRegisterModal(false)} />
        </div>
      }

      {showLoginModal &&
        <div className="modal-overlay" onClick={() => setShowLoginModal(false)}>
          <LoginModal closeModal={() => setShowLoginModal(false)} />
        </div>
      }
    </div>
  );
};

export default Auth;