import React, { useState, useEffect } from 'react';
import ilData from './Data/il.json';
import ilceData from './Data/ilce.json';
import koyData from './Data/koy.json';
import mahalleData from './Data/mahalle.json';
import './AddressFilter.css';

const AddressFilter = () => {
    const [selectedIl, setSelectedIl] = useState('');
    const [selectedIlceler, setSelectedIlceler] = useState([]);
    const [selectedKoyler, setSelectedKoyler] = useState([]);
    const [selectedMahalleler, setSelectedMahalleler] = useState([]);
  
    const ilceler = ilceData.filter(ilce => ilce.il_id === selectedIl);
    const filteredKoyler = koyData.filter(koy => selectedIlceler.includes(koy.ilce_id));
    const filteredMahalleler = mahalleData.filter(mahalle =>
      selectedKoyler.includes(mahalle.koy_id)
    );
  
    const handleCheckbox = (id, selectedList, setSelectedList) => {
      setSelectedList(prev =>
        prev.includes(id) ? prev.filter(item => item !== id) : [...prev, id]
      );
    };
  
    return (
      <div className="address-filter">
        <h4 className='konumfilter'>Konum Ara:</h4>
        <select onChange={(e) => {
          setSelectedIl(e.target.value);
          setSelectedIlceler([]);
          setSelectedKoyler([]);
          setSelectedMahalleler([]);
        }} value={selectedIl}>
          <option value="">-- İl Seçiniz --</option>
          {ilData.map(il => (
            <option key={il.id} value={il.id}>{il.name}</option>
          ))}
        </select>
  
        {selectedIl && (
          <>
            <h4 className='konumfilter'>İlçeleri Seç:</h4>
            <div className="checkbox-list">
              {ilceler.map(ilce => (
                <label key={ilce.id}>
                  <input
                    type="checkbox"
                    checked={selectedIlceler.includes(ilce.id)}
                    onChange={() => {
                      handleCheckbox(ilce.id, selectedIlceler, setSelectedIlceler);
                      setSelectedKoyler([]); // ilçe değişince köyler sıfırlanır
                      setSelectedMahalleler([]); // mahalleler de
                    }}
                  />
                  {ilce.name}
                </label>
              ))}
            </div>
  
            {filteredKoyler.length > 0 && (
              <>
                <h4 className='konumfilter'>Köyleri Seç:</h4>
                <div className="checkbox-list">
                  {filteredKoyler.map(koy => (
                    <label key={koy.id}>
                      <input
                        type="checkbox"
                        checked={selectedKoyler.includes(koy.id)}
                        onChange={() => {
                          handleCheckbox(koy.id, selectedKoyler, setSelectedKoyler);
                          setSelectedMahalleler([]); // köy değişince mahalleler sıfırlanır
                        }}
                      />
                      {koy.name}
                    </label>
                  ))}
                </div>
              </>
            )}
  
            {filteredMahalleler.length > 0 && (
              <>
                <h4 className='konumfilter'>Mahalleleri Seç:</h4>
                <div className="checkbox-list">
                  {filteredMahalleler.map(mahalle => (
                    <label key={mahalle.id}>
                      <input
                        type="checkbox"
                        checked={selectedMahalleler.includes(mahalle.id)}
                        onChange={() => handleCheckbox(mahalle.id, selectedMahalleler, setSelectedMahalleler)}
                      />
                      {mahalle.name}
                    </label>
                  ))}
                </div>
              </>
            )}
          </>
        )}
      </div>
    );
  };
  
  export default AddressFilter;