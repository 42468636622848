import React, { useEffect, useState } from "react";
import API_BASE from "./config";

export default function Orders({ email }) {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!email) return;
    fetch(`${API_BASE}/get_orders.php`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email })
    })
      .then(res => res.json())
      .then(data => {
        if (data.status === "success") {
          setOrders(data.siparisler);
        }
        setLoading(false);
      })
      .catch(err => {
        console.error("Sipariş verileri alınamadı", err);
        setLoading(false);
      });
  }, [email]);

  if (loading) return <p className="mt-6">Yükleniyor...</p>;
  if (!orders.length) return <p className="mt-6 text-gray-500">Herhangi bir sipariş bulunamadı.</p>;

  return (
    <div className="overflow-x-auto mt-6">
    <table className="min-w-full border border-gray-300">
      <thead>
        <tr className="bg-gray-100 text-left text-sm">
          <th className="px-4 py-2 border">Aidat Tipi</th>
          <th className="px-4 py-2 border">Cihaz</th>
          <th className="px-4 py-2 border">Monitör</th>
          <th className="px-4 py-2 border">Toplam</th>
          <th className="px-4 py-2 border">Başlangıç</th>
          <th className="px-4 py-2 border">Bitiş</th>
          <th className="px-4 py-2 border">Durum</th>
        </tr>
      </thead>
      <tbody>
        {orders.map((siparis, i) => (
          <tr key={i} className="text-sm hover:bg-gray-50">
            <td className="px-4 py-2 border">{siparis.aidat_tipi}</td>
            <td className="px-4 py-2 border">{siparis.cihaz_secildi ? "Evet" : "Hayır"}</td>
            <td className="px-4 py-2 border">{siparis.monitor_model || "Yok"}</td>
            <td className="px-4 py-2 border">{siparis.toplam_tutar}₺</td>
            <td className="px-4 py-2 border">{siparis.baslangic_tarihi}</td>
            <td className="px-4 py-2 border">{siparis.bitis_tarihi}</td>
            <td className="px-4 py-2 border font-medium">
              {siparis.odeme_durumu === 1 ? (
                <span className="text-green-600">Ödendi</span>
              ) : (
                <button className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600 transition text-sm">
                  Öde
                </button>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

}
