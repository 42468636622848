import React, { useState } from "react";
import API_BASE from "./config";
const LoginModal = ({ closeModal }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [forgotMode, setForgotMode] = useState(false);
  const [forgotEmail, setForgotEmail] = useState("");
  const [forgotMessage, setForgotMessage] = useState("");

  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    setLoading(true);

    try {
      const response = await fetch(`${API_BASE}/login.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      if (response.ok && result.status === "success") {
        localStorage.setItem("user", JSON.stringify({ name: result.name, email: result.email }));
        window.location.reload();
      } else {
        setMessage(result.error || "Giriş başarısız.");
      }
    } catch (err) {
      setMessage("Sunucuya bağlanılamadı.");
    } finally {
      setLoading(false);
    }
  };

  const handleForgotSubmit = async () => {
    setForgotMessage("Gönderiliyor...");
    try {
      const res = await fetch(`${API_BASE}/forgot_password.php`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: forgotEmail })
      });
      const result = await res.json();
      if (res.ok && result.success) {
        setForgotMessage("Şifre sıfırlama bağlantısı e-posta adresinize gönderildi.");
      } else {
        setForgotMessage(result.error || "Gönderilemedi.");
      }
    } catch {
      setForgotMessage("Sunucuya bağlanılamadı.");
    }
  };

  return (
    <div className="modal-content" onClick={(e) => e.stopPropagation()} style={{ padding: '24px', maxWidth: '400px' ,display:"flex" , flexDirection:"column" , width:"100%" }}>
      <h2 style={{fontFamily:"roboto" ,margin:"15px" , marginBottom:"10px" ,textAlign:"center" , color:"grey"}}>Giriş Yap</h2>
      <form onSubmit={handleSubmit} className="login-form" style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
        <input
        style={{  padding: "8px", marginBottom: "8px" ,border:"1px solid grey" ,borderRadius:"5px" }}
          type="email"
          name="email"
          placeholder="E-posta"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <input
        style={{  padding: "8px", marginBottom: "8px" ,border:"1px solid grey" ,borderRadius:"5px" }}
          type="password"
          name="password"
          placeholder="Şifre"
          value={formData.password}
          onChange={handleChange}
          required
        />
        <button type="submit" disabled={loading} className="submit-button">
          {loading ? "Kontrol ediliyor..." : "Giriş Yap"}
        </button>
        <button type="button" className="close-button" onClick={closeModal}>
          Kapat
        </button>
        {message && <p style={{ color: "red", textAlign: "center" }}>{message}</p>}
      </form>

      <div style={{ marginTop: "16px", textAlign: "center" }}>
        <button
          onClick={() => setForgotMode(!forgotMode)}
          style={{ background: "none", border: "none", color: "#007bff", cursor: "pointer", fontSize: "14px" }}
        >
          Şifremi unuttum
        </button>
      </div>

      {forgotMode && (
        <div style={{ marginTop: "16px" ,display:"flex" ,flexDirection:"column" ,alignItems:"center" }}>
          <input
            type="email"
            placeholder="Mail adresinizi giriniz"
            value={forgotEmail}
            onChange={(e) => setForgotEmail(e.target.value)}
            style={{ width: "100%", padding: "8px", marginBottom: "8px" ,border:"1px solid grey" ,borderRadius:"5px" }}
          />
          <button
            onClick={handleForgotSubmit}
            className="submit-button"
            style={{ width: "100%" }}
          >
            Gönder
          </button>
          {forgotMessage && <p style={{ textAlign: "center", marginTop: "8px" }}>{forgotMessage}</p>}
        </div>
      )}
    </div>
  );
};

export default LoginModal;