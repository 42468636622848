import React, { useState } from 'react';
import categories from './Data/categories.json';
import './CategoryTree.css';

const subCategories = {
  "1": {
    "Kiralık": ["Daire", "Villa", "Müstakil Ev"],
    "Satılık": ["Daire", "Villa", "Müstakil Ev"]
  },
  "2": ["Kiralık", "Satılık", "Devren Satılık", "Devren Kiralık"]
};

const CategoryTree = () => {
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [expandedMain, setExpandedMain] = useState(null); // Kiralık/Satılık
  const [selectedOptions, setSelectedOptions] = useState({
    Kiralık: [],
    Satılık: [],
    boyut:[],
    binaDurumu: '',
    devreDurumu: '',tesisDurumu: '',
    tesisTurleri: [],    

    filters: {
      odaSayisi: [],
      binaYasi: '',
      asansor: '',
      otopark: '',
      esyali: '',
      kullanim: '',
      site: '',
      keyword: '',
      isyeriDurumu: '',
      arsaDurumu: '',
      imarDurumu: '',
      tapuDurumu: '',
      boyut: [],
      binaDurumu: '',
      devreDurumu: '',
      tesisDurumu: '',
      tesisTurleri: []
    }
    

    
    
    
  });

  const toggleMain = (main) => {
    setExpandedMain(prev => (prev === main ? null : main));
    setSelectedOptions(prev => ({
      ...prev,
      [main === "Kiralık" ? "Satılık" : "Kiralık"]: []
    }));
  };

  const handleOptionChange = (main, value) => {
    setSelectedOptions(prev => {
      const alreadySelected = prev[main].includes(value);
      const updated = alreadySelected
        ? prev[main].filter(item => item !== value)
        : [...prev[main], value];
      return { ...prev, [main]: updated };
    });
  };

  const handleFilterChange = (key, value, multi = false) => {
    setSelectedOptions(prev => {
      const current = prev.filters[key];
      const updated = multi
        ? current.includes(value)
          ? current.filter(v => v !== value)
          : [...current, value]
        : value;
      return {
        ...prev,
        filters: {
          ...prev.filters,
          [key]: updated
        }
      };
    });
  };

  const handleSearch = () => {
    console.log("API'ye gönderilecek filtreler:", selectedOptions);
    // örnek: fetch('/api/ilanlar', { method: 'POST', body: JSON.stringify(selectedOptions) })
  };

  const selectedSubTree = subCategories[selectedCategoryId];

  return (
    <div className="category-tree">
      <h4 className='konumfilter'>Kategori Seç:</h4>
      <select
        className="category-select"
        value={selectedCategoryId}
        onChange={(e) => {
          setSelectedCategoryId(e.target.value);
          setExpandedMain(null);
          setSelectedOptions({
                       Kiralık: [],
            Satılık: [],
            filters: {
              odaSayisi: [],
              binaYasi: '',
              asansor: '',
              otopark: '',
              esyali: '',
              kullanim: '',
              site: ''
            }
          });
        }}
      >
        <option value="">-- Kategori Seçin --</option>
        {categories.map(cat => (
          <option key={cat.id} value={cat.id}>{cat.name}</option>
        ))}
      </select>

      {selectedCategoryId === "1" && selectedSubTree && (
  <div className="tree-view">
    <ul>
      {Object.keys(selectedSubTree).map((main) => (
        <li key={main} className="tree-node">
          <div
            className="tree-label"
            onClick={() => toggleMain(main)}
          >
            ▶ {main}
          </div>
          {expandedMain === main && (
            <ul className="sub-branch">
              {selectedSubTree[main].map((leaf, i) => (
                <label key={i}>
                  <input
                    type="checkbox"
                    checked={selectedOptions[main]?.includes(leaf)}
                    onChange={() => handleOptionChange(main, leaf)}
                  />
                  {leaf}
                </label>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  </div>
)}


      {/* Filtreler sadece Konut için */}
      {selectedCategoryId === "1" && (
        <div className="filters">
          <h4>Oda Sayısı</h4>
          {["1+0", "1+1", "2+1", "3+1", "4+1", "5+1 ve üzeri"].map((oda, i) => (
            <label key={i}>
              <input
                type="checkbox"
                checked={selectedOptions.filters.odaSayisi.includes(oda)}
                onChange={() => handleFilterChange("odaSayisi", oda, true)}
              />
              {oda}
            </label>
          ))}

          <h4>Bina Yaşı</h4>
          {["0", "1-5", "5-10", "Daha yaşlı"].map((yas, i) => (
            <label key={i}>
              <input
                type="radio"
                name="binaYasi"
                checked={selectedOptions.filters.binaYasi === yas}
                onChange={() => handleFilterChange("binaYasi", yas)}
              />
              {yas}
            </label>
          ))}

          <h4>Asansör</h4>
          {["Var", "Yok"].map((val, i) => (
            <label key={i}>
              <input
                type="radio"
                name="asansor"
                checked={selectedOptions.filters.asansor === val}
                onChange={() => handleFilterChange("asansor", val)}
              />
              {val}
            </label>
          ))}

          <h4>Otopark</h4>
          {["Var", "Yok"].map((val, i) => (
            <label key={i}>
              <input
                type="radio"
                name="otopark"
                checked={selectedOptions.filters.otopark === val}
                onChange={() => handleFilterChange("otopark", val)}
              />
              {val}
            </label>
          ))}

          <h4>Eşya Durumu</h4>
          {["Eşyalı", "Eşyasız"].map((val, i) => (
            <label key={i}>
              <input
                type="radio"
                name="esyali"
                checked={selectedOptions.filters.esyali === val}
                onChange={() => handleFilterChange("esyali", val)}
              />
              {val}
            </label>
          ))}

          <h4>Kullanım Durumu</h4>
          {["Kiracılı", "Boş", "Mülk Sahibi"].map((val, i) => (
            <label key={i}>
              <input
                type="radio"
                name="kullanim"
                checked={selectedOptions.filters.kullanim === val}
                onChange={() => handleFilterChange("kullanim", val)}
              />
              {val}
            </label>
          ))}

          <h4>Site İçerisinde mi?</h4>
          {["Evet", "Hayır"].map((val, i) => (
            <label key={i}>
              <input
                type="radio"
                name="site"
                checked={selectedOptions.filters.site === val}
                onChange={() => handleFilterChange("site", val)}
              />
              {val}
            </label>
          ))}

          <button className="search-button" onClick={handleSearch}>Ara</button>
        </div>
      )}
           {/* İşyeri için alt kategori + kelime arama */}
           {selectedCategoryId === "2" && (
  <div className="filters">
    <h4>İşyeri Durumu</h4>
    <select
      value={selectedOptions.filters.isyeriDurumu || ''}
      onChange={(e) =>
        setSelectedOptions(prev => ({
          ...prev,
          filters: {
            ...prev.filters,
            isyeriDurumu: e.target.value
          }
        }))
      }
      className="category-select"
    >
      <option value="">-- Seçiniz --</option>
      <option value="Kiralık">Kiralık</option>
      <option value="Satılık">Satılık</option>
      <option value="Devren Satılık">Devren Satılık</option>
      <option value="Devren Kiralık">Devren Kiralık</option>
    </select>

    <h4>Anahtar Kelime</h4>
    <input
      type="text"
      placeholder="Anahtar kelime girin..."
      value={selectedOptions.filters.keyword || ''}
      onChange={(e) =>
        setSelectedOptions(prev => ({
          ...prev,
          filters: {
            ...prev.filters,
            keyword: e.target.value
          }
        }))
      }
      className="keyword-input"
    />

    <button className="search-button" onClick={handleSearch}>Ara</button>
  </div>
)}

{selectedCategoryId === "3" && (
  <div className="filters">
    <h4>Durum</h4>
    {["Satılık", "Kiralık"].map((val, i) => (
      <label key={i}>
        <input
          type="radio"
          name="arsaDurumu"
          checked={selectedOptions.filters.arsaDurumu === val}
          onChange={() =>
            setSelectedOptions(prev => ({
              ...prev,
              filters: {
                ...prev.filters,
                arsaDurumu: val
              }
            }))
          }
        />
        {val}
      </label>
    ))}

    <h4>İmar Durumu</h4>
    {["Var", "Yok"].map((val, i) => (
      <label key={i}>
        <input
          type="radio"
          name="imarDurumu"
          checked={selectedOptions.filters.imarDurumu === val}
          onChange={() =>
            setSelectedOptions(prev => ({
              ...prev,
              filters: {
                ...prev.filters,
                imarDurumu: val
              }
            }))
          }
        />
        {val}
      </label>
    ))}

    <h4>Tapu Durumu</h4>
    {["Hisseli Tapu", "Tek Tapu"].map((val, i) => (
      <label key={i}>
        <input
          type="radio"
          name="tapuDurumu"
          checked={selectedOptions.filters.tapuDurumu === val}
          onChange={() =>
            setSelectedOptions(prev => ({
              ...prev,
              filters: {
                ...prev.filters,
                tapuDurumu: val
              }
            }))
          }
        />
        {val}
      </label>
    ))}
<h4>Arsa Boyutu</h4>
{["0-500 m²", "500-1000 m²", "1000-3000 m²", "3000-10000 m²", "Daha büyük"].map((val, i) => (
  <label key={i}>
    <input
      type="checkbox"
      checked={selectedOptions.filters.boyut?.includes(val)}
      onChange={() => {
        setSelectedOptions(prev => {
          const current = prev.filters.boyut || [];
          const updated = current.includes(val)
            ? current.filter(v => v !== val)
            : [...current, val];
          return {
            ...prev,
            filters: {
              ...prev.filters,
              boyut: updated
            }
          };
        });
      }}
    />
    {val}
  </label>
))}

    <h4>Anahtar Kelime</h4>
    <input
      type="text"
      placeholder="Anahtar kelime girin..."
      value={selectedOptions.filters.keyword || ''}
      onChange={(e) =>
        setSelectedOptions(prev => ({
          ...prev,
          filters: {
            ...prev.filters,
            keyword: e.target.value
          }
        }))
      }
      className="keyword-input"
    />

    <button className="search-button" onClick={handleSearch}>Ara</button>
  </div>
)}
{selectedCategoryId === "4" && (
  <div className="filters">
    <h4>Durum</h4>
    {["Kiralık", "Satılık"].map((val, i) => (
      <label key={i}>
        <input
          type="radio"
          name="binaDurumu"
          checked={selectedOptions.filters.binaDurumu === val}
          onChange={() =>
            setSelectedOptions(prev => ({
              ...prev,
              filters: {
                ...prev.filters,
                binaDurumu: val
              }
            }))
          }
        />
        {val}
      </label>
    ))}

    <h4>Anahtar Kelime</h4>
    <input
      type="text"
      placeholder="Anahtar kelime girin..."
      value={selectedOptions.filters.keyword || ''}
      onChange={(e) =>
        setSelectedOptions(prev => ({
          ...prev,
          filters: {
            ...prev.filters,
            keyword: e.target.value
          }
        }))
      }
      className="keyword-input"
    />

    <button className="search-button" onClick={handleSearch}>Ara</button>
  </div>
)}
{selectedCategoryId === "6" && (
  <div className="filters">
    <h4>Durum</h4>
    {["Kiralık", "Satılık"].map((val, i) => (
      <label key={i}>
        <input
          type="radio"
          name="devreDurumu"
          checked={selectedOptions.filters.devreDurumu === val}
          onChange={() =>
            setSelectedOptions(prev => ({
              ...prev,
              filters: {
                ...prev.filters,
                devreDurumu: val
              }
            }))
          }
        />
        {val}
      </label>
    ))}

    <h4>Anahtar Kelime</h4>
    <input
      type="text"
      placeholder="Anahtar kelime girin..."
      value={selectedOptions.filters.keyword || ''}
      onChange={(e) =>
        setSelectedOptions(prev => ({
          ...prev,
          filters: {
            ...prev.filters,
            keyword: e.target.value
          }
        }))
      }
      className="keyword-input"
    />

    <button className="search-button" onClick={handleSearch}>Ara</button>
  </div>
)}
{selectedCategoryId === "5" && (
  <div className="filters">
    <h4>Durum</h4>
    {["Kiralık", "Satılık"].map((val, i) => (
      <label key={i}>
        <input
          type="radio"
          name="tesisDurumu"
          checked={selectedOptions.filters.tesisDurumu === val}
          onChange={() =>
            setSelectedOptions(prev => ({
              ...prev,
              filters: {
                ...prev.filters,
                tesisDurumu: val
              }
            }))
          }
        />
        {val}
      </label>
    ))}

    <h4>Tesis Türü</h4>
    {["Otel", "Apart Otel", "Pansiyon", "Kamp Alanı", "Plaj", "Tatil Köyü"].map((val, i) => (
      <label key={i}>
        <input
          type="checkbox"
          checked={selectedOptions.filters.tesisTurleri?.includes(val)}
          onChange={() => {
            setSelectedOptions(prev => {
              const current = prev.filters.tesisTurleri || [];
              const updated = current.includes(val)
                ? current.filter(v => v !== val)
                : [...current, val];
              return {
                ...prev,
                filters: {
                  ...prev.filters,
                  tesisTurleri: updated
                }
              };
            });
          }}
        />
        {val}
      </label>
    ))}

    <h4>Anahtar Kelime</h4>
    <input
      type="text"
      placeholder="Anahtar kelime girin..."
      value={selectedOptions.filters.keyword || ''}
      onChange={(e) =>
        setSelectedOptions(prev => ({
          ...prev,
          filters: {
            ...prev.filters,
            keyword: e.target.value
          }
        }))
      }
      className="keyword-input"
    />

    <button className="search-button" onClick={handleSearch}>Ara</button>
  </div>
)}

    </div>
  );
};

export default CategoryTree;
