import React, { useEffect, useState } from "react";
import KurumsalaGecisFormu from "./KurumsalaGecisFormu";
import Orders from "./Orders";
import API_BASE from "./config";
export default function Settings() {
  const [userData, setUserData] = useState(null);
  const [kurumsalData, setKurumsalData] = useState(null);
  const [siparisData, setSiparisData] = useState(null);
  const [isCorporate, setIsCorporate] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [formInput, setFormInput] = useState({ name: "", password: "" });

  useEffect(() => {
    const stored = localStorage.getItem("user");
    if (!stored) return;

    const user = JSON.parse(stored);
    fetch(`${API_BASE}/kurumsalinfo.php`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: user.email })
    })
      .then(res => res.json())
      .then(data => {
        if (data.status === "success") {
          setUserData(user);
          setFormInput({ name: user.name, password: "" });
          setIsCorporate(data.is_corporate);
          setKurumsalData(data.kurumsal);
          setSiparisData(data.siparis);
        }
      })
      .catch(err => console.error("Kurumsal bilgi alınamadı", err));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormInput(prev => ({ ...prev, [name]: value }));
  };

  const handleUpdate = () => {
    fetch(`${API_BASE}/update-user.php`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: userData.email,
        name: formInput.name,
        password: formInput.password
      })
    })
      .then(res => res.json())
      .then(data => {
        if (data.status === "success") {
          const updatedUser = { ...userData, name: formInput.name };
          setUserData(updatedUser);
          localStorage.setItem("user", JSON.stringify(updatedUser));
          setEditMode(false);
          alert("Bilgileriniz güncellendi.");
        }
      })
      .catch(err => alert("Güncelleme sırasında hata oluştu."));
  };

  if (!userData) return <div className="text-center p-10">Yükleniyor...</div>;

  const calculateRemainingDays = () => {
    if (!siparisData?.bitis_tarihi) return "-";
    const now = new Date();
    const end = new Date(siparisData.bitis_tarihi);
    const diff = Math.ceil((end - now) / (1000 * 60 * 60 * 24));
    return diff > 0 ? `${diff} gün kaldı` : "Süre doldu";
  };

  return (
    <div className="max-w-6xl mx-auto p-6 bg-white rounded-lg shadow">
      {/* ÜST BİLGİLENDİRME */}
      {isCorporate && kurumsalData && siparisData && (
        <div className="bg-green-100 border border-green-400 text-green-800 p-4 mb-6 rounded text-center">
            <div className="bg-green-100 border border-green-300 rounded-xl p-6 mb-8 text-center shadow-md">
          <h2 className="text-2xl font-bold text-green-700 mb-2">🎉 Dükkanınız Aktif!</h2></div>
          <p><strong>Dükkan:</strong> <a href={`https://${kurumsalData.shop_name}.emlakvitrini.net`} target="_blank" className="underline">{kurumsalData.shop_name}.emlakvitrini.net</a></p>
          <p><strong>Abonelik Bitiş Tarihi:</strong> {siparisData.bitis_tarihi}</p>
          <p><strong>Kalan Gün:</strong> {calculateRemainingDays()}</p>
        </div>
      )}

      <h2 className="text-2xl font-bold mb-6">Kullanıcı Ayarları</h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
        {/* KİŞİSEL BİLGİLER */}
        <div>
          <h3 className="text-xl font-semibold mb-4">KİŞİSEL BİLGİLER</h3>
          <div className="space-y-4">
            <input
              type="text"
              name="name"
              value={formInput.name}
              onChange={handleChange}
              readOnly={!editMode}
              className={`w-full p-2 border rounded ${editMode ? "" : "bg-gray-100"}`}
            />
            <input
              type="email"
              name="email"
              value={userData.email}
              readOnly
              className="w-full p-2 border rounded bg-gray-100"
            />
            <input
              type="password"
              name="password"
              value={formInput.password}
              onChange={handleChange}
              placeholder="Yeni şifre (boş bırakırsanız değişmez)"
              readOnly={!editMode}
              className={`w-full p-2 border rounded ${editMode ? "" : "bg-gray-100"}`}
            />
          </div>
          {editMode ? (
            <div className="flex gap-2 mt-4">
              <button className="px-4 py-2 bg-green-600 text-white rounded" onClick={handleUpdate}>Kaydet</button>
              <button className="px-4 py-2 bg-gray-400 text-white rounded" onClick={() => setEditMode(false)}>İptal</button>
            </div>
          ) : (
            <button className="mt-4 px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded" onClick={() => setEditMode(true)}>
              Düzenle
            </button>
          )}

          <div className="mt-6">
            <button
              className={`px-4 py-2 rounded text-white ${isCorporate ? "bg-gray-400 cursor-not-allowed" : "bg-blue-600 hover:bg-blue-700"}`}
              disabled={isCorporate}
              onClick={() => setShowForm(true)}
            >
              {isCorporate ? "Zaten Kurumsal" : "Kurumsala Geç"}
            </button>
          </div>
        </div>

        {/* KURUMSAL PANEL */}
        <div>
          <h3 className="text-xl font-roboto mb-4">KURUMSAL BİLGİLER</h3>
          {isCorporate ? (
            <ul className="space-y-2 text-sm">
              <li><strong>Şirket Adı:</strong> {kurumsalData?.sirketAdi}</li>
              <li><strong>Vergi Dairesi:</strong> {kurumsalData?.vergiDairesi}</li>
              <li><strong>Yetkili:</strong> {kurumsalData?.yetkiliKisi}</li>
              <li><strong>Telefon:</strong> {kurumsalData?.telefon}</li>
              <li><strong>Adres:</strong> {kurumsalData?.adres}</li>
              <li><strong>Kayıt Tarihi:</strong> {kurumsalData?.kayitTarihi}</li>
            </ul>
          ) : (
            showForm && <KurumsalaGecisFormu />
          )}
        </div>
      </div>

      {/* GEÇMİŞ SİPARİŞLER */}
      {isCorporate && <div className="mt-12">
        <h3 className="text-xl font-semibold mb-4">Geçmiş Siparişler</h3>
        <Orders email={userData.email} />
      </div>}
    </div>
  );
}
