import React, { useState, useEffect } from "react";
import API_BASE from "./config";
const RegisterModal = ({ closeModal }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    auth_provider: "email",
  });

  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(() => {
    try {
      const savedUser = localStorage.getItem("user");
      return savedUser ? JSON.parse(savedUser) : null;
    } catch {
      return null;
    }
  });

  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    setMessageType("");
    setLoading(true);

    try {
      const response = await fetch(`${API_BASE}/register.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
        mode: "cors",
      });

      const result = await response.json();

      if (response.ok) {
        setMessage(result.message || "Kayıt başarılı.");
        setMessageType("success");
      
        // 1 saniye sonra modalı kapat
        setTimeout(() => {
          closeModal();
          window.location.reload(); // kullanıcı bilgisi güncellensin
        }, 3000);
      }else {
        setMessage(result.error || "Kayıt başarısız.");
        setMessageType("error");
      }
    } catch (err) {
      setMessage("Sunucuya bağlanılamadı.");
      setMessageType("error");
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSuccess = async (response) => {
    try {
      const res = await fetch(`${API_BASE}/register.php`,{
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id_token: response.credential }),
       
      });

      const result = await res.json();
      if (res.ok && result.status === "success") {
        const userData = { name: result.name, email: result.email ,auth_provider: "google" };
        setUser(userData);
        localStorage.setItem("user", JSON.stringify(userData));
        window.location.reload();
        closeModal();
      } else {
        setMessage(result.error || "Google ile kayıt başarısız.");
        setMessageType("error");
        
      }
    } catch (error) {
      setMessage("Google ile kayıt başarısız.");
      setMessageType("error");
    }
  };

  useEffect(() => {
    /* global google */
    if (window.google && google.accounts.id) {
      google.accounts.id.initialize({
        client_id: "89160158516-thdinmoa0j34piuephubsncjsmofs9a4.apps.googleusercontent.com",
        callback: handleGoogleSuccess,
      });
      google.accounts.id.renderButton(
        document.getElementById("google-button"),
        { theme: "outline", size: "large", width: "100%" }
      );
    }
  }, []);

  if (user) {
    return (
      <div className="user-info">
        <span>👤 {user.name}</span>
        <div className="hamburger-menu">☰</div>
      </div>
    );
  }

  return (
    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
      <h2 style={{fontFamily:"roboto" ,margin:"15px" , marginBottom:"10px",textAlign:"center" ,color:"grey"}}>Kayıt Ol</h2>
      <form onSubmit={handleSubmit} className="register-form">
        <input
          type="text"
          name="name"
          placeholder="Ad Soyad"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <input
          type="email"
          name="email"
          placeholder="E-posta"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <input
          type="password"
          name="password"
          placeholder="Şifre"
          value={formData.password}
          onChange={handleChange}
          required
        />
        <button type="submit" className="submit-button" disabled={loading}>
          {loading ? "Kaydediliyor..." : "Kaydol"}
        </button>
        <p className="close-button">veya</p>
        <div  style={{ display: "flex", justifyContent: "center", marginTop: "0px" , width:"100%" ,alignItems:"center" }}>
  <div id="google-button" style={{ width: "100%" }}></div>
</div>
        <button type="button" className="close-button" onClick={closeModal}>
          Kapat
        </button>
      </form>

      {message && (
        <p
          style={{
            marginTop: "5px",
            color: messageType === "success" ? "green" : "red",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          {message}
        </p>
      )}
    </div>
  );
};

export default RegisterModal;