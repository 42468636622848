import React, { useState  ,useEffect} from "react";
import aidat from "./Data/aidat.json";
import cihaz from "./Data/cihaz.json";
import monitorOptions from "./Data/monitor.json";
import API_BASE from "./config";
const steps = ["Kurumsal Bilgi", "Sipariş", "Ödeme", "Tamamlandı"];

export default function KurumsalaGecisFormu() {
  const [step, setStep] = useState(1);
  
  const [formData, setFormData] = useState({
    sirketAdi: "",
    vergiDairesi: "",
    adres: "",
    telefon: "",
    yetkiliKisi: "",
    tcKimlikNo: "",
    kullaniciAdi: "",
    aidat: "",
    cihaz: false,
    monitor: "",
    kartIsim: "",
    kartNumara: "",
    sonKullanma: "",
    cvv: "",
    kargoFaturaAyni: false,
    kargoAdresi: "",
  });

  useEffect(() => {
    if (step === 4) {
      const user = JSON.parse(localStorage.getItem("user"));
      fetch(`${API_BASE}/siparis_mail.php`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: user?.email,
                  sirketAdi: formData.sirketAdi,
          kullaniciAdi: formData.kullaniciAdi,
          aidat: formData.aidat === "yillik" ? "Yıllık" : "Aylık",
          monitor: monitorOptions.find(m => m.id === formData.monitor)?.isim || "Yok",
          cihaz: formData.cihaz,
          toplam: toplam,
          kargoAdres: formData.kargoAdresi || formData.adres
        })
      })
      .then(res => res.json())
      .then(data => {
        console.log("📧 Mail sonucu:", data);
      })
      .catch(err => console.error("❌ Mail gönderilemedi:", err));
    }
  }, [step]);
  

  const [kullaniciAdiUygunMu, setKullaniciAdiUygunMu] = useState(true);
  const [tcHata, setTcHata] = useState("");

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
  
    setFormData((prev) => ({
      ...prev,
      [name]: newValue
    }));
  
    // Kullanıcı adı değiştiyse hatayı sıfırla
    if (name === "kullaniciAdi") {
      setKullaniciAdiUygunMu(true); // kullanıcı başka bir ad girince kırmızı yazı kaybolur
    }
  };
  
  const checkUsername = async () => {
    if (!formData.kullaniciAdi) return;
  
    try {
      const res = await fetch(`${API_BASE}/check_username.php`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ kullaniciAdi: formData.kullaniciAdi })
      });
  
      const result = await res.json();
      setKullaniciAdiUygunMu(!result.exists);
    } catch (err) {
      console.error("❌ Kullanıcı adı kontrolü başarısız:", err);
    }
  };
  
  
  const handleKurumsalSubmit = async (e) => {
    e.preventDefault();
    const girilen = formData.tcKimlikNo.trim();

    if (!/^[0-9]+$/.test(girilen)) {
      setTcHata("Sadece rakam giriniz.");
      return;
    }

    if (girilen.length === 11) {
      const digits = girilen.split("").map(Number);
      const [d1, d2, d3, d4, d5, d6, d7, d8, d9, d10, d11] = digits;
      const checksum1 = ((d1 + d3 + d5 + d7 + d9) * 7 - (d2 + d4 + d6 + d8)) % 10;
      const checksum2 = (digits.slice(0, 10).reduce((a, b) => a + b, 0)) % 10;

      if (d10 !== checksum1 || d11 !== checksum2) {
        setTcHata("Geçersiz TC Kimlik Numarası.");
        return;
      }
    } else if (girilen.length !== 10) {
      setTcHata("Lütfen geçerli bir TC Kimlik No (11 hane) veya Vergi No (10 hane) girin.");
      return;
    }

    const user = JSON.parse(localStorage.getItem("user"));

    try {
      const response = await fetch(`${API_BASE}/registerkurumsal.php`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: user?.email || "",
          sirketAdi: formData.sirketAdi,
          vergiDairesi: formData.vergiDairesi,
          adres: formData.adres,
          telefon: formData.telefon,
          yetkiliKisi: formData.yetkiliKisi,
          tcVergiNo: formData.tcKimlikNo
                })
      });
    
      const text = await response.text();
      try {
        const result = JSON.parse(text);
        if (!response.ok || result.error) {
          setTcHata(result.error || "Kayıt başarısız.");
          return;
        }
        setTcHata("");
        setStep(2);
      } catch (jsonErr) {
        console.error("❌ Geçersiz JSON:", text);
        setTcHata("Sunucu yanıtı alınamadı.");
      }
    } catch (err) {
      console.error("❌ HATA:", err);
      setTcHata("Sunucu hatası.");
    }
    
  };

  const handleSiparisSubmit = async (e) => {
    e.preventDefault();
  
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      alert("Kullanıcı bilgisi bulunamadı.");
      return;
    }
  
    const payload = {
      email: user.email,
      kullaniciAdi: formData.kullaniciAdi,
      aidatTipi: formData.aidat,
      cihaz: formData.cihaz ? 1 : 0,
      monitor: formData.monitor,
      toplamTutar: toplam,
      kargoAdresi: formData.kargoFaturaAyni ? formData.adres : formData.kargoAdresi
      
    };
  
    try {
      const response = await fetch(`${API_BASE}/orders.php`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload)
      });
  
      const result = await response.json();
      if (!response.ok || result.error) {
        alert(result.error || "Sipariş kaydedilemedi.");
        return;
      }
  
      setStep(3); // ödeme sayfasına geç
    } catch (err) {
      console.error("❌ Sipariş gönderimi hatası:", err);
      alert("Sunucu hatası oluştu.");
    }
  };
  
  

  const handleOdemeSubmit = (e) => {
    e.preventDefault();
    console.log("Ödeme yapıldı:", formData);
    setStep(4);
  };

  const aidatTutar = aidat[formData.aidat] || 0;
  const aidatIndirimi = formData.cihaz && cihaz.aidatBedava ? aidatTutar : 0;
  const cihazTutar = formData.cihaz ? cihaz.fiyat : 0;
  const monitorTutar = monitorOptions.find(m => m.id === formData.monitor)?.fiyat || 0;
  const toplam = cihazTutar + monitorTutar + (aidatTutar - aidatIndirimi);
  const progressWidth = `${(step / steps.length) * 100}%`;

  return (
    
    <div className="min-h-screen bg-gray-50 p-6">
       {/* Progress Bar */}
      <div className="max-w-3xl mx-auto mb-8">
        <div className="w-full bg-gray-200 rounded-full h-2">
          <div className="bg-green-600 h-2 rounded-full" style={{ width: progressWidth }}></div>
        </div>
        <div className="flex justify-between text-sm mt-2 text-gray-600">
          {steps.map((s, i) => (
            <span key={i} className={`${step === i + 1 ? "text-green-700 font-semibold" : ""}`}>{s}</span>
          ))}
        </div>
      </div>

      {/* Adım 1 */}
      {step === 1 && (
        <form onSubmit={handleKurumsalSubmit} className="max-w-xl mx-auto bg-white p-6 rounded-xl shadow-md">
          <h2 className="text-xl font-semibold mb-4">Kurumsal Bilgiler</h2>

          <label className="block mb-4">
            <span className="text-gray-700">TC Kimlik No (11 hane) veya Vergi No (10 hane)</span>
            <input
              type="text"
              name="tcKimlikNo"
              value={formData.tcKimlikNo}
              onChange={handleChange}
              className="w-full mt-1 p-2 border border-gray-300 rounded"
            />
            {tcHata && <p className="text-red-500 text-sm mt-1">{tcHata}</p>}
          </label>

          {["sirketAdi", "vergiDairesi", "telefon", "yetkiliKisi"].map((name) => (
            <label className="block mb-4" key={name}>
              <span className="text-gray-700">{name.replace(/([A-Z])/g, " $1").replace(/^\w/, c => c.toUpperCase())}</span>
              <input
                type="text"
                name={name}
                value={formData[name]}
                onChange={handleChange}
                required
                className="w-full mt-1 p-2 border border-gray-300 rounded"
              />
            </label>
          ))}

          <label className="block mb-6">
            <span className="text-gray-700">Adres</span>
            <textarea
              name="adres"
              value={formData.adres}
              onChange={handleChange}
              required
              rows="3"
              className="w-full mt-1 p-2 border border-gray-300 rounded"
            />
          </label>

          <button type="submit" className="bg-green-600 text-white px-6 py-2 rounded hover:bg-green-700 transition">
            Devam Et
          </button>
        </form>
      )}

      {/* Adım 2 */}
      {step === 2 && (
  <form onSubmit={handleSiparisSubmit} className="max-w-xl mx-auto bg-white p-6 rounded-xl shadow-md">
    <h2 className="text-xl font-semibold mb-4">Kullanıcı ve Sipariş Detayları</h2>

    {/* Kullanıcı Adı */}
    <label className="block mb-4">
  <span className="text-gray-700">Kullanıcı Adı</span>
  <div className="flex items-center">
    <input
      type="text"
      name="kullaniciAdi"
      value={formData.kullaniciAdi}
      onChange={handleChange}
      onBlur={checkUsername}
      required
      className={`w-full p-2 border rounded-l ${!kullaniciAdiUygunMu ? 'border-red-500' : ''}`}
    />
    <span className="bg-gray-100 px-3 py-2 border border-l-0 border-gray-300 rounded-r text-sm text-gray-600">
      .emlakvitrini.net
    </span>
  </div>

  {!kullaniciAdiUygunMu && (
    <p className="text-red-500 text-sm mt-1">Bu kullanıcı adı zaten alınmış. Lütfen başka bir isim deneyin.</p>
  )}
  <p className="text-gray-500 text-sm mt-1">Dükkan adınız: {formData.kullaniciAdi || "kullaniciadi"}.emlakvitrini.net olacaktır.</p>
</label>



    {/* Aidat Seçimi */}
    <label className="block mb-4">
      <span className="text-gray-700">Aidat Seçimi</span>
      <select
        name="aidat"
        value={formData.aidat}
        onChange={handleChange}
        required
        className="w-full mt-1 p-2 border border-gray-300 rounded"
      >
        <option value="">Seçiniz</option>
        <option value="aylik">Aylık - {aidat.aylik}₺</option>
        <option value="yillik">Yıllık - {aidat.yillik}₺</option>
      </select>
    </label>

    {/* Cihaz ve Monitör */}
    <label className="block mb-2">
      <input type="checkbox" name="cihaz" checked={formData.cihaz} onChange={handleChange} className="mr-2" />
      {cihaz.adi} ({cihaz.fiyat}₺) - Seçiliyse aidat ücretsiz!
    </label>

    <label className="block mb-4">
      <span className="text-gray-700">Monitör Seçimi</span>
      <select
        name="monitor"
        value={formData.monitor}
        onChange={handleChange}
        className="w-full mt-1 p-2 border border-gray-300 rounded"
      >
        <option value="">Monitör seçin (isteğe bağlı)</option>
        {monitorOptions.map(opt => (
          <option key={opt.id} value={opt.id}>{opt.isim} ({opt.fiyat}₺)</option>
        ))}
      </select>
    </label>

    {/* ✅ KARGO ADRESİ */}
    <label className="block mb-2">
      <input
        type="checkbox"
        checked={formData.kargoFaturaAyni || false}
        onChange={(e) => {
          const checked = e.target.checked;
          setFormData(prev => ({
            ...prev,
            kargoFaturaAyni: checked,
            kargoAdresi: checked ? prev.adres : ""
          }));
        }}
        className="mr-2"
      />
      Kargo adresim fatura adresimle aynı
    </label>

    {!formData.kargoFaturaAyni && (
      <label className="block mb-4">
        <span className="text-gray-700">Kargo Adresi</span>
        <textarea
          name="kargoAdresi"
          value={formData.kargoAdresi || ""}
          onChange={handleChange}
          rows="3"
          required
          className="w-full mt-1 p-2 border border-gray-300 rounded"
        />
      </label>
    )}

    {/* Sipariş Özeti */}
    <div className="bg-gray-50 border p-4 rounded text-sm font-mono">
      <p className="mb-1">📦 Sipariş Özeti</p>
      <p>Aidat: {formData.cihaz ? <><s>{aidatTutar}₺</s> <span className="text-green-600 font-semibold">0₺</span></> : `${aidatTutar}₺`}</p>
      {formData.cihaz && <p>{cihaz.adi}: {cihazTutar}₺</p>}
      {formData.monitor && <p>Monitör: {monitorTutar}₺</p>}
      <p className="mt-2 font-bold">Toplam: {toplam}₺</p>
    </div>

    <button type="submit" className="mt-4 bg-green-600 text-white px-6 py-2 rounded hover:bg-green-700 transition">
      Devam Et
    </button>
  </form>
)}
{step === 3 && (
  <div className="max-w-xl mx-auto bg-white p-6 rounded-xl shadow-md">
    <h2 className="text-xl font-semibold mb-4">Ödeme Sayfası</h2>

    <div className="bg-gray-100 p-4 rounded text-sm mb-6">
      <p className="font-semibold">Toplam Tutar: <span className="text-green-700 text-lg">{toplam}₺</span></p>
      <p className="text-gray-500">Demo POS ekranı (gerçek ödeme entegre edilmedi)</p>
    </div>

    <div className="flex gap-4">
      <button
        onClick={async () => {
          try {
            const user = JSON.parse(localStorage.getItem("user"));
            const response = await fetch(`${API_BASE}/update_payment.php`, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ email: user.email })
            });
            const result = await response.json();
            if (result.status === "success") {
              setStep(4);
            } else {
              alert("Ödeme kaydı güncellenemedi.");
            }
          } catch (err) {
            console.error("❌ Ödeme güncelleme hatası:", err);
            alert("Sunucu hatası oluştu.");
          }
        }}
        className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700"
      >
        ✅ Ödeme Onaylandı
      </button>

      <button
        onClick={() => alert("❌ Ödeme başarısız. Lütfen tekrar deneyin.")}
        className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700"
      >
        ❌ Ödeme Başarısız
      </button>
    </div>
  </div>
)}

{step === 4 && (

  <div className="max-w-xl mx-auto bg-white p-6 rounded-xl shadow-md text-center">
    <h2 className="text-2xl font-bold text-green-600 mb-4">🎉 Tebrikler! Siparişiniz Alındı</h2>
    <p className="text-gray-700 mb-6">Kurumsal üyeliğiniz başarıyla oluşturuldu.</p>

    <div className="text-left space-y-2 text-sm bg-gray-50 p-4 rounded border">
      <p><strong>🏢 Şirket Adı:</strong> {formData.sirketAdi}</p>
      <p><strong>👤 Yetkili Kişi:</strong> {formData.yetkiliKisi}</p>
      <p><strong>📞 Telefon:</strong> {formData.telefon}</p>
      <p><strong>🏷️ Dükkan Adı:</strong> {formData.kullaniciAdi}.emlakvitrini.net</p>
      <p><strong>📦 Abonelik:</strong> {formData.aidat === "yillik" ? "Yıllık" : "Aylık"}</p>
      <p><strong>🖥️ Monitör:</strong> {formData.monitor ? monitorOptions.find(m => m.id === formData.monitor)?.isim : "Yok"}</p>
      <p><strong>📍 Kargo Adresi:</strong> {formData.kargoAdres || formData.adres}</p>
      <p><strong>💳 Ödeme Durumu:</strong> <span className="text-green-600 font-semibold">Onaylandı</span></p>
    </div>

    <div className="mt-6">
      <a href="/" className="inline-block px-6 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition">Anasayfaya Dön</a>
    </div>
  </div>
)}

    </div>
  );
}
