import React, { useEffect } from "react";
import "./Navbar.css";
import emlaklogo from "./logo2.png";
import Auth from "./Auth";

const NavbarWithSearch = ({ showSearch }) => {
  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector(".navbar-top");
      const placeholder = document.querySelector(".navbar-placeholder");
      const searchContainer = document.querySelector(".search-container");

      if (window.scrollY > 60) {
        navbar?.classList.add("hide");
        placeholder?.classList.add("active");
        searchContainer?.classList.add("sticky");
      } else {
        navbar?.classList.remove("hide");
        placeholder?.classList.remove("active");
        searchContainer?.classList.remove("sticky");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="navbar-wrapper">
      {/* Üst Logo ve Giriş */}
      <div className="navbar-top">
        <img src={emlaklogo} className="logo" alt="emlakLOGO" />
        <div className="auth-buttons">
          <Auth />
        </div>
      </div>

      {/* Scroll İçin Placeholder */}
      <div className="navbar-placeholder"></div>

      {/* Arama Alanı */}
      {showSearch && (
        <div className="search-container">
          <input
            className="search-bar"
            type="text"
            placeholder="İlan, konum, emlakçı ara..."
          />
        </div>
      )}
    </div>
  );
};

export default NavbarWithSearch;
