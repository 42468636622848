import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter, Router } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<GoogleOAuthProvider  clientId="89160158516-thdinmoa0j34piuephubsncjsmofs9a4.apps.googleusercontent.com">
  <BrowserRouter>
     <App /> 
  </BrowserRouter>
     
  
        
   
   
</GoogleOAuthProvider>
    

);
